.Scan-Qr{
    max-height: 100vh;
    opacity: 0;
    animation: fadeIn .4s ease forwards;

    .bottom-content{
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        bottom: 3rem;

        .add-number{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            animation: show-card .4s ease forwards;

            .line{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}