.login {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow: auto;
}

.top {
  flex-grow: 1;
  position: relative;
  padding: 1.5rem;
}

.form {
  animation: show-group 1s ease forwards;
}

.phone {
  opacity: 0;
  animation: show .8s .1s ease forwards;
}

.password {
  opacity: 0;
  animation: show .8s .2s ease forwards;
}

.button {
  opacity: 0;
  animation: show .8s .3s ease forwards;
}

.forgot-password {
  text-align: center;
  font-size: calculateRem(14px);
  margin-top: 1rem;
  cursor: pointer;
  opacity: 0;
  animation: show .8s .6s ease forwards;
}

.topbar {
  height: 5rem;
}

.title {
  font-size: 35px;
  font-weight: bold;
  opacity: 0;
  animation: show .8s .1s ease forwards;
}

.subtitle {
  font-size: 14px;
  font-weight: normal;
  margin-top: .2rem;
  margin-bottom: 3rem;
  color: #a2a2a2;
  opacity: 0;
  animation: show .8s .2s ease forwards;
}

.register {
  padding: 2rem;
  text-align: center;
  font-size: 15px;
  opacity: 0;
  animation: show .8s .5s ease forwards;
  span {
    color: $primary-color;
    font-weight: bold;
    cursor: pointer;
  }
}