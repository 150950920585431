.history {
  .navigation {
    background-color: white;
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
    overflow: hidden;

    .title {
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
      padding: 0 1rem;
      opacity: 0;
    }

    .category {
      display: flex;
      gap: .5rem;
      padding: 0 1rem;
      overflow: auto;
      margin-top: 1rem;
      opacity: 0;
      animation: show-card 1s .1s ease forwards;

      .tab {
        background-color: white;
        border: 1px solid #ddd;
        padding: .4rem .8rem;
        border-radius: 2rem;
        height: 100%;
        font-size: .8rem;
        white-space: nowrap;

        &.active {
          background-color: #2e4a8a;
          border-color: #2e4a8a;
          color: white;
        }
      }
    }
  }

  .card {
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    min-height: 3rem;

    .content {
      display: flex;
      gap: 1rem;
    }

    .type {
      font-size: 1rem;
      font-weight: 600;
    }

    .receipt {
      font-size: .8rem;
    }

    .product {
      font-size: .8rem;
      font-weight: 600;
    }

    .point {
      font-size: .8rem;
      font-weight: 600;
      color: #2e4a8a;
    }

    .date {
      margin-top: .5rem;
      font-size: .8rem;
      color: #979797;
    }
  }

  .empty {
    text-align: center;
    padding: 1rem;
  }
}