.input-member-container{
    padding: 0 1rem;

    .top-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding:  .5rem 0;
    }


    >img{
        object-fit: contain;
    }

    .bottom-content{
        padding: 2rem 0;
        .line{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}