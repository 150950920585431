.topbar {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  border-bottom: 1px solid #f5f5f5;
  background-color: #ffffff;
  z-index: 100;

  .title {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .back {
    cursor: pointer;

    .icon {
      display: block;
      height: 1.5rem;
    }
  }
}