.edit-profile-container {
  background-color: white;
  height: 100%;
  opacity: 0;
  animation: show-card .5s ease forwards;

  .profile-picture-box {
    height: 6rem;
    width: 6rem;
    border-radius: 10rem;
    padding: .2rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;

    .profile-picture-container{
      height: 100%;
      position: relative; 
      
      .profile-picture {
        width: 100%;
        height: 100%;
        border-radius: 10rem;
        object-fit: cover;
      }
      
      .profile-picture-placeholder {
        height: 100%;
        border-radius: 100rem;
      }

      .icon-container {
        position: absolute;
        border-radius: 2rem;
        bottom: 0;
        right: 0;
        background-color: white; 
        padding: 4px;
        cursor: pointer;
  
        .icon{
          object-fit: contain;
          width: 1rem;
        }
      }
      
    }
    
  }
  
  .label {
    font-size: .8rem;
    font-weight: 500;
    margin-top: .5rem;
  }
}