.create-membership-container {
    background-color: white;
    height: 100%;
    opacity: 0;
    animation: show-card .5s ease forwards;
  
    .content{

      >label {
        display: block;
        margin:.5rem 0 .3rem 0;
      }
      
      .gender-container{
        display: flex;
        align-items: center;
        gap: 3rem;
        
        .gender-label{
          display: flex;
          align-items: center;
          gap: .3rem;
        }
      }
    }
  }