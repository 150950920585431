.splash-screen {
  background-color: $primary-color;
  height: 100dvh;
}

.logo {
  @include center(both);
  img {
    opacity: 0;
    height: 4rem;
    animation: splash-logo 1s .3s ease forwards;
  }
}