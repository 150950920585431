.home {
  .header {
    background-color: #2e4a8a;
    padding: 1rem;
    border-radius: 0 0 2rem 2rem;

    .title {
      font-size: 1rem;
      color: white;
      display: flex;
      align-items: center;

      .back {
        cursor: pointer;
        margin-right: auto;
        position: absolute;
    
        .icon {
          display: block;
          height: 1.5rem;
          filter: contrast(0) brightness(100);
        }
      }

      .center {
        flex-grow: 1;
        display: flex;
        justify-content: center;
     }
    }

    .feature {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      margin: 1.5rem 0;
      opacity: 0;
      animation: show-card 1s ease forwards;

      >div {
        flex-basis: 33.3%;
        cursor: pointer;

        .icon {
          height: 1.2rem;
          filter: contrast(0) brightness(100);
        }

        .label {
          font-size: .7rem;
          font-weight: 500;
          color: white;
        }
      }
    }

    .point-expense {
      display: flex;
      gap: .5rem;
      opacity: 0;
      animation: show-card 1s .1s ease forwards;

      .card {
        position: relative;
        flex-basis: 33%;
        padding: 10px;
        border-radius: 1.5rem;
        background-color: #fff;
        // border: 1px solid #f5f5f5;
        overflow: hidden;
        text-align: center // boxShadow: 0 1px 3px rgba(0;0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .2rem;
        margin: 0;

        .icon {
          height: 26px;
          width: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f9f9f9;
          padding: 5px;
          border-radius: 40%;
          margin-bottom: 8px;
          opacity: 0;
          animation: show-card-brand 1s .2s ease forwards;
        }

        .amount {
          font-size: 12px;
          font-weight: 700;
          line-height: .8;
          color: #000;
        }

        .label {
          font-size: 10px;
          color: #333;
        }
      }
    }

    .member-card {
      opacity: 0;
      margin-top: 1rem;
      animation: show-card 1s .1s ease forwards;
      margin-bottom: -7rem;

      .content {
        position: relative;
        border-radius: 1.5rem;
        background-color: #f9f9f9;
        // backgroundColor: #2e4a8a;
        border: 1px solid #ddd;
        overflow: hidden;
        margin: 0;

        .icon {
          position: absolute;
          top: 1rem;
          left: 1rem;
          height: 60px;
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
          border-radius: 30%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          // border: 1px solid #eee;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          opacity: 0;
          animation: show-card-brand 1s .5s ease forwards;
          z-index: 2;
        }

        .user {
          background-color: white;
          padding: 2rem 1rem .5rem 1rem;
          margin-top: 0;
          animation: show-card-top 1s .5s ease forwards;
          border-top-left-radius: 1.4rem;
          border-top-right-radius: 1.4rem;

          .name {
            font-weight: 800;
          }

          .badge {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: .5rem;

            .content {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: .2rem;
              background-color: #eee;
              padding: 5px 8px;
              border-radius: 1rem;
              margin-bottom: 8;
              font-size: 12px;
              font-weight: 500;

              .badge-icon {
                height: 15px;
                filter: brightness(0);
                opacity: .6;
              }
            }
          }
        }
      }
    }
  }

  .content {
    margin-top: 3rem;

    .feature-merchant {
      display: grid;
      grid-template-columns: repeat(auto-fill, 24%);
      grid-gap: 0.3rem;
      justify-content: space-between;
      padding: 1rem;
      align-items: center;
      opacity: 0;
      animation: show-card 1s .5s ease forwards;
    
      .content {
        max-width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        
          .icon {
            object-fit: contain;
            max-width: 2.3rem;
          }
    
        span {
          font-size: medium; 
        }
      }
    }

    .promotion {
      background-color: #ffffff;
      box-sizing: border-box;
      margin: .5rem 1rem;
      overflow: hidden;
      opacity: 0;
      animation: show-card 1s .5s ease forwards;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
  
        .title {
          font-size: 1rem;
          font-weight: bold;
        }
  
        .see-all {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: .5rem;
          padding: .2rem .5rem;
          background-color: gray;
          border-radius: 1rem;
          cursor: pointer;
  
          span {
            font-size: .9rem;
            font-weight: 500;
            color: #ffffff;
          }
  
          .arrow {
            display: block;
            height: .6rem;
            filter: brightness(0) invert(1);
          }
        }
      }
  
      .banner {
        display: block;
        width: 100%;
        border-radius: 1.5rem;
      }
  
      .swiper-horizontal>.swiper-scrollbar,
      .swiper-scrollbar.swiper-scrollbar-horizontal {
        bottom: .8rem;
        transform: scaleX(.2) scaleY(1.5);
      }
    }

    .popular-catalogue {
      background-color: #ffffff;
      box-sizing: border-box;
      margin: .5rem 1rem;
      overflow: hidden;
      opacity: 0;
      animation: show-card 1s .5s ease forwards;

      .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        .title {
          font-size: 1rem;
          font-weight: bold;
        }

        .see-all {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: .5rem;
          padding: .2rem .5rem;
          background-color: gray;
          border-radius: 1rem;
          cursor: pointer;
  
          span {
            font-size: .9rem;
            font-weight: 500;
            color: #ffffff;
          }
  
          .arrow {
            display: block;
            height: .6rem;
            filter: brightness(0) invert(1);
          }
        }
        
      }


      .catalogue {
        display: flex;
        gap: 1rem;
        padding: 0 1rem;
        overflow: auto;

        .card {
          border: 1px solid #ddd;
          border-radius: 1rem;
          cursor: pointer;

          .detail {
            padding: .5rem;

            .name {
              min-height: 2rem;
              font-size: .8rem;
              white-space: pre-wrap;
              word-break: keep-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .point {
              font-size: .8rem;
              font-weight: 600;
              margin-top: .2rem;
              color: #2e4a8a;
            }
          }
        }
      }
    }
  }
}