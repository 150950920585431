@keyframes splash-logo {
  from {
    opacity: 0;
    transform: scale(2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-card {
  from {
    opacity: 0;
    transform: translateY(10rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-card-brand {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes show-card-top {
  from {
    margin-top: 0;
  }

  to {
    margin-top: 3.5rem;
  }
}

@keyframes show-group {
  from {
    opacity: 0;
    transform: translateY(5rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes show-tab {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes show-badge {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
