.register-otp {
 background-color: white;
 height: 100%;
 padding: 20px;

    .resend{
        justify-content: center;
        display: flex;
        padding: 20px 0;
    }
}