.input-wrapper {
  position: relative;

  .input-bg {
    position: absolute;
    top: 0;
    left: 10px;
    width: 30px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    background-size: 30px 20px;
    pointer-events: none;
  }

  .country-code {
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
    font-size: 0.9rem;
    pointer-events: none;
  }

  input {
    width: 100%;
    box-sizing: border-box;
    border-radius: .75rem;
    border: 1px solid #eee;
    outline: 1px solid transparent;
    background-color: #f9f9f9;
    padding: .8rem;
    margin: .5rem 0;
    font-size: .9rem;
    transition: all .3s ease;

    &:hover {
      border-color: #eee;
    }

    &:focus,
    &:focus-visible,
    &:active {
      border: 1px solid #999;
      outline: 3px solid #6d91df1a;
    }
  }
}
