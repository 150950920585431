.add-card{

    .content{
        padding: 1rem;

        .card-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            opacity: 0;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
            margin-bottom: 1rem;
            border-radius: 1rem;
            padding: 0 1rem;

            .merchant{
                display: flex;
                align-items: center;
                gap: 1rem;
                .icon{
                    height: 5rem;
                    width: 5rem;
                    object-fit: contain;
                    border-radius: 3rem;
                }
                
                >span{
                    font-weight: bold;
                }
            }

            .add{
                background-color: $primary-color;
                color: #ffffff;
                padding: .3rem;
                border-radius: 1rem;
            }

        }
    }
}