@mixin center($position) {
  position: absolute;
  
  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  }
  @else if $position == 'horizontal' {
    left: 50%;
    transform: translate(-50%);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}