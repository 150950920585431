.store-container {
    .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
  
      .card {
        background-color: #ffffff;
        border-radius: 1.5rem;
        border: 1px solid #eee;
        box-sizing: border-box;
        overflow: hidden;
  
        .image {
          display: block;
          width: 100%;
          border-radius: 1rem;
          background-color: #eee;
        }
  
        .detail {
          padding: 1rem;
  
          .title {
            font-size: 1rem;
            font-weight: 600;
          }
  
          .date {
            display: flex;
            align-items: center;
            gap: .5rem;
            margin-top: .5rem;
  
            .icon {
              height: 1rem;
              opacity: .6;
            }
  
            span {
              font-size: .8rem;
              font-weight: 500;
              color: #7d7d7d;
            }
          }
        }
      }
    }
  }