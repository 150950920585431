.container {
  position: relative;
  margin: 0 auto;
  height: 100dvh;
  box-sizing: border-box;
  overflow: auto;
  max-width: $max-width;
  background-color: white;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.modal {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 100%;
  max-width: 28rem;
  background-color: rgba(0, 0, 0, 0);
  z-index: 100;
  visibility: hidden;
  overflow: auto;
  transition: all .5s ease;
  z-index: 9999999999;

  &.show {
    visibility: visible;
    background-color: rgba(0, 0, 0, .8);
    transition: all 1s ease;

    .code {
      transform: translate(-50%, -50%);
      transition: all .5s ease;
    }
  }

  .code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 200%);
    transition: all .3s ease;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
    padding: 1rem .5rem;

    .voucher-code {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: 'Jost', sans-serif;
      font-weight: bold;
      font-size: 1.2rem;
      color: #545454;
      text-align: center;

      .copy {
        height: 1.2rem;
        margin-left: .3rem;
      }
    }

    .choose-code {
      position: relative;
      display: flex;
      margin: 1rem;
      background-color: #eee;
      border-radius: 2rem;

      .selected {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 50%;
        height: 100%;
        padding: 4px;
        box-sizing: border-box;
        z-index: 1;
        user-select: none;
        transition: .3s ease;

        .button {
          background-color: white;
          border-radius: 2rem;
          height: 100%;
          box-sizing: border-box;
        }

        &.qr {
          transform: translate(100%, -50%);
        }
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .2rem;
        flex-basis: 50%;
        padding: 1rem;
        z-index: 2;
        cursor: pointer;

        &.active {
          background-color: white;
        }

        .icon {
          height: .8rem;
        }

        span {
          font-family: 'Jost', sans-serif;
          font-weight: bold;
          font-size: .7rem;
          white-space: nowrap;
          color: #545454;
        }
      }
    }
  }
}